require("isomorphic-fetch");

window["orderTimeout"] = function(fn, delay = 500) {
  let delayCount = 0;

  return (...params) => {
    delayCount++;

    return new Promise((resolve) =>
      setTimeout(() => {
        delayCount--;

        return resolve(fn(...params));
      }, (delayCount - 1) * delay)
    );
  };
};
const setAuthToken = (obj) =>
  Object.assign(
    obj,
    ((token) => (token ? { Authorization: "Bearer " + token } : {}))(
      window.$jwt.getToken()
    )
  );

function makeid(length) {
  let result = [];

  // ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789
  let characters = "0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    );
  }
  return result.join("");
}
function prepareUserRole(needSuperadmin) {
  const prepare = window.$getRoleToSend();
  if (needSuperadmin) prepare.role = "superadmin";
  return JSON.stringify(prepare);
}

// const BASE_URL = 'http://localhost:3399/api/v1/'
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
// const CONECTX_URL = 'https://api.achizitii.conectx.net/api/v1/'

const headerTypeEmpty = () => ({
  "Content-Type": "application/json",
  Accept: "application/json",
  "Access-Control-Allow-Headers": "Content-Type",
  "Access-Control-Allow-Origin": "*",
});
const headerTypeDefault = (needSuperadmin) =>
  setAuthToken({
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Headers": "Content-Type",
    "Access-Control-Allow-Origin": "*",
    "conectx-userid-role": prepareUserRole(needSuperadmin),
  });
const headerTypeDownload = () =>
  setAuthToken({
    Accept: "*/*",
    "Content-Type": "application/octet-stream",
    "Accept-Encoding": "gzip,deflate,br",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
    "conectx-userid-role": prepareUserRole(),
  });
const headerTypeFormData = () =>
  setAuthToken({
    Accept: "*/*",
    "Accept-Encoding": "gzip,deflate,br",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
    "conectx-userid-role": prepareUserRole(),
  });

const fetch = (...params) => {
  let isBlob = false;

  if (params[0] === true) {
    isBlob = true;
    params.shift();
  }

  const safeCallFn = (fn) => (typeof fn == "function" ? fn() : null);

  return window.fetch(...params).then(async (res) => {
    let prepare = null;

    if (isBlob) {
      prepare = res.blob();
    } else {
      prepare = await res.json();

      if (prepare?.statusCode === 542) {
        safeCallFn(window.$logout);
        safeCallFn(window.$roleInvalid);

        return null;
      }
    }

    return prepare;
  });
};

export const REGISTRU_PAGINATED = (
  pageNumber = 1,
  recordsPerPage = 15,
  ...search
) =>
  fetch(
    `${BASE_URL}registrul-pragurilor/?recordsPerPage=${String(
      recordsPerPage
    ).trim()}&pageNumber=${String(pageNumber).trim()}` +
      (search.slice(-1)[0] ? `&${search.slice(-1)}` : ""),
    {
      method: "GET",
      headers: headerTypeDefault(),
    }
  );

export const REGISTRU_MODIFY = (id, data) =>
  fetch(`${BASE_URL}registrul-pragurilor/${id}`, {
    method: "PATCH",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const REGISTRU_ADD = (data) =>
  fetch(`${BASE_URL}registrul-pragurilor/`, {
    method: "POST",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const REGISTRU_DELETE = (id) =>
  fetch(`${BASE_URL}registrul-pragurilor/${id}`, {
    method: "DELETE",
    headers: headerTypeDefault(),
  });

export const CURRENCY = () =>
  fetch(`${BASE_URL}currency/current/`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const AVIZO = (needId) =>
  fetch(`${BASE_URL}need-avizo/?need.id=${needId}`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const LOGIN = (username, password) =>
  fetch(`${BASE_URL}auth/login/`, {
    method: "POST",
    body: JSON.stringify({
      email: username,
      password: password,
    }),
    headers: headerTypeEmpty(),
  }).catch((err) => err);

export const AUTO_LOGIN = (token) =>
  fetch(`${BASE_URL}auth/conectxlogin`, {
    method: "POST",
    body: JSON.stringify({ token }),
    headers: headerTypeEmpty(),
  }).catch((err) => err);

export const REQUEST_PASSWORD_RESET = (email) =>
  fetch(`${BASE_URL}auth/reset-password/${email}`, {
    method: "POST",
    headers: headerTypeDefault(),
  }).catch((err) => err);

export const PASSWORD_RESTORE = (data) =>
  fetch(`${BASE_URL}auth/restore-password/`, {
    method: "POST",
    headers: headerTypeEmpty(),
    body: JSON.stringify(data),
  }).catch((err) => err);
export const CHECK_PASSWORD_TOKEN = (data) =>
  fetch(`${BASE_URL}auth/check-password-token`, {
    method: "POST",
    headers: headerTypeEmpty(),
    body: JSON.stringify(data),
  }).catch((err) => err);

export const REGISTER = (first_name, last_name, email, password) =>
  fetch(`${BASE_URL}auth/register/`, {
    method: "POST",
    body: JSON.stringify({
      cnp: String(makeid(13)),
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone: "+04 123123313",
      password: password,
      profile_picture: "-",
      userRoles: [],
    }),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Headers": "Content-Type",
      "Access-Control-Allow-Origin": "*",
    },
  });

export const USERS = () =>
  fetch(`${BASE_URL}users/`, {
    method: "GET",
    headers: headerTypeDefault(true),
  });

export const USERS_FROM_INSTITUTION = (
  institutionId,
  pageNumber,
  recordsPerPage,
  ...filter
) =>
  fetch(
    `${BASE_URL}users/institution/${institutionId}?recordsPerPage=${String(
      recordsPerPage
    ).trim()}&pageNumber=${String(pageNumber).trim()}${
      filter.at(-1) ? `?${filter.at(-1)}` : ""
    }`,
    {
      method: "GET",
      headers: headerTypeDefault(true),
    }
  );

export const USER_CREATE_USERNAME = (data) =>
  fetch(`${BASE_URL}users/create/username`, {
    method: "POST",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const USER_ME = () =>
  fetch(`${BASE_URL}users/me/`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const USER_ME_MODIFY = (body) =>
  fetch(`${BASE_URL}users/me`, {
    method: "PATCH",
    headers: headerTypeDefault(),
    body: JSON.stringify(body),
  });

export const USERS_SEARCH = (key, value) =>
  fetch(`${BASE_URL}users/?eq=${key},${value}`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const USERS_ADD = (data) =>
  fetch(`${BASE_URL}users/`, {
    method: "POST",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const USERS_ADD_ROLE = (data) =>
  fetch(`${BASE_URL}user-roles/`, {
    method: "POST",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const USERS_ROLES = (uuid) =>
  fetch(`${BASE_URL}user-roles?userUuid=${uuid}`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const USERS_ROLES_PAGINATED = (
  pageNumber = 1,
  recordsPerPage = 15,
  ...filter
) =>
  fetch(
    `${BASE_URL}user-roles/institution?recordsPerPage=${String(
      recordsPerPage
    ).trim()}&pageNumber=${String(pageNumber).trim()}` +
      (filter.at(-1) ? `&${filter.at(-1)}` : ""),
    {
      method: "GET",
      headers: headerTypeDefault(),
    }
  );

export const CONFIRM_ROLE = (id) =>
  fetch(`${BASE_URL}user-roles/confirm/${id}`, {
    method: "PATCH",
    headers: headerTypeDefault(),
  });

export const RESEND_ROLE = (id) =>
  fetch(`${BASE_URL}user-roles/resendemail/${id}`, {
    method: "POST",
    headers: headerTypeDefault(),
  });

export const EDIT_ROLE = (id, data) =>
  fetch(`${BASE_URL}user-roles/${id}`, {
    method: "PATCH",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const ACTIVATE_ROLE = (id, reason) =>
  fetch(`${BASE_URL}user-roles/activate/${id}`, {
    method: "PATCH",
    headers: headerTypeDefault(),
    body: JSON.stringify({ reason }),
  });

export const USERS_PAGINATED = (
  pageNumber = 1,
  recordsPerPage = 15,
  o,
  search
) =>
  fetch(
    `${BASE_URL}users/?recordsPerPage=${String(
      recordsPerPage
    ).trim()}&pageNumber=${String(pageNumber).trim()}` +
      (search ? `&${search}` : ""),
    {
      method: "GET",
      headers: headerTypeDefault(),
    }
  );

export const USERS_ORIGIN_PAGINATED = (
  pageNumber = 1,
  recordsPerPage = 15,
  o,
  search
) =>
  fetch(
    `${BASE_URL}users/get-my-institution-users/?recordsPerPage=${String(
      recordsPerPage
    ).trim()}&pageNumber=${String(pageNumber).trim()}` +
      (search ? `&${search}` : ""),
    {
      method: "GET",
      headers: headerTypeDefault(),
    }
  );

export const USERS_LENGTH = () =>
  fetch(`${BASE_URL}users/length/get/`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const USERS_DELETE = (uuid) =>
  fetch(`${BASE_URL}users/${uuid}`, {
    method: "DELETE",
    headers: headerTypeDefault(),
  });

export const USERS_DELETE_ROLE = (id, reason) =>
  fetch(`${BASE_URL}user-roles/${id}`, {
    method: "DELETE",
    headers: headerTypeDefault(),
    body: JSON.stringify({ reason }),
  });

export const USERS_ORIGIN = () =>
  fetch(`${BASE_URL}needs/approval/users/`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const USERS_FROM_CURENT_USER_DEPARTMENT = () =>
  fetch(`${BASE_URL}users/department`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const USERS_INSTITUTION = () =>
  fetch(`${BASE_URL}needs/approval/users/`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const USERS_ONCE = (id, needSuperadmin) =>
  fetch(`${BASE_URL}users/uuid/${id}`, {
    method: "GET",
    headers: headerTypeDefault(needSuperadmin),
  });

export const USERS_CHECK_ROLE = (roleId) =>
  fetch(`${BASE_URL}users/check-role-dismount/${roleId}`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const USER_MODIFY = (id, data) =>
  fetch(`${BASE_URL}users/${id}`, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: headerTypeDefault(),
  });

export const USER_AVATAR_DOWNLOAD = () =>
  fetch(true, `${BASE_URL}users/download/`, {
    method: "GET",
    headers: headerTypeDownload(),
  });

export const ANOTHER_USER_DOWNLOAD = window["orderTimeout"]((uuid) =>
  fetch(true, `${BASE_URL}users/download/${uuid}`, {
    method: "GET",
    headers: headerTypeDownload(),
  })
);

export const USER_AVATAR_UPLOAD = (file) =>
  fetch(`${BASE_URL}users/upload/`, {
    method: "POST",
    headers: setAuthToken({
      Accept: "*/*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Origin": "*",
      "conectx-userid-role": prepareUserRole(),
    }),
    body: file,
  });

export const PRODUCTS = () =>
  fetch(`${BASE_URL}products/?isActive=1&neq=status,rejected`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const PRODUCTS_CAN_EDIT_CPV = (id) =>
  fetch(`${BASE_URL}products/canpatch/${id}`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const PRODUCTS_ONCE = (id) =>
  fetch(`${BASE_URL}products/${id}`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const PRODUCTS_PAGINATED = (
  pageNumber = 1,
  recordsPerPage = 15,
  a,
  search
) =>
  fetch(
    `${BASE_URL}products/?recordsPerPage=${String(
      recordsPerPage
    ).trim()}&pageNumber=${String(pageNumber).trim()}` +
      (search ? `&${search}` : ""),
    {
      method: "GET",
      headers: headerTypeDefault(),
    }
  );

export const PRODUCT_VERSIONS = (id, vers) =>
  fetch(`${BASE_URL}products/${id}/versions${vers ? `/${vers}` : ""}`, {
    method: "GET",
    headers: headerTypeDefault(),
  });
export const PRODUCT_LAST_VERSION = (id) =>
  fetch(`${BASE_URL}products/version/${id}/last`, {
    method: "GET",
    headers: headerTypeDefault(),
  });
export const PRODUCTS_ADD = (data) =>
  fetch(`${BASE_URL}products/`, {
    method: "POST",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const PRODUCTS_MODIFY = (id, data) =>
  fetch(`${BASE_URL}products/${id}`, {
    method: "PATCH",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const PRODUCTS_DELETE = (id, data) =>
  fetch(`${BASE_URL}products/${id}`, {
    method: "DELETE",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const PRODUCTS_DELETE_REJECTED = (id) =>
  fetch(`${BASE_URL}products/rejected/${id}`, {
    method: "DELETE",
    headers: { ...headerTypeDefault(), submethod: "rejected" },
  });

export const INSTITUTIONS = (a, b, c, search) =>
  fetch(
    `${BASE_URL}institutions/?recordsPerPage=1000&pageNumber=1` +
      (search ? `&${search}` : ""),
    {
      method: "GET",
      headers: headerTypeDefault(),
    }
  );

export const INSTITUTIONS_PAGINATED = (
  pageNumber = 1,
  recordsPerPage = 15,
  search,
  filter
) =>
  fetch(
    `${BASE_URL}institutions/?recordsPerPage=${String(
      recordsPerPage
    ).trim()}&pageNumber=${String(pageNumber).trim()}` +
      (search ? `&${search[0]}=${search[1]}` : "") +
      (filter ? `&${filter}` : ""),
    {
      method: "GET",
      headers: headerTypeDefault(),
    }
  );

export const INSTITUTION = (id) =>
  fetch(`${BASE_URL}institutions/${id}`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const INSTITUTION_ORGANIZATIONAL_STRUCTURE = (id) =>
  fetch(`${BASE_URL}institutions/organizational-structure?id=${id}`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const INSTITUTION_UPLOAD = (id, src) =>
  fetch(`${BASE_URL}institutions/upload/${id}`, {
    method: "PATCH",
    headers: setAuthToken({
      Accept: "*/*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Origin": "*",
      "conectx-userid-role": prepareUserRole(),
    }),
    body: src,
  });

export const INSTITUTION_DOWNLOAD = (id) =>
  fetch(true, `${BASE_URL}institutions/download/${id}`, {
    method: "GET",
    headers: headerTypeDownload(),
  });

export const INSTITUTION_LENGTH = () =>
  fetch(`${BASE_URL}institutions/length/get/`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const INSTITUTION_ADD = (data) =>
  fetch(`${BASE_URL}institutions/`, {
    method: "POST",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const INSTITUTION_MODIFY = (id, data) =>
  fetch(`${BASE_URL}institutions/${id}`, {
    method: "PATCH",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const INSTITUTION_DELETE = (id) =>
  fetch(`${BASE_URL}institutions/${id}`, {
    method: "DELETE",
    headers: headerTypeDefault(),
  });
export const INSTITUTION_INFO = (data) =>
  fetch(`${BASE_URL}institutions/sync`, {
    method: "PATCH",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });
export const DEPARTMENT = (query) =>
  fetch(`${BASE_URL}departments/${query || ""}`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const DEPARTMENTS_PAGINATED = (
  pageNumber = 1,
  recordsPerPage = 15,
  ...search
) =>
  fetch(
    `${BASE_URL}departments/?recordsPerPage=${String(
      recordsPerPage
    ).trim()}&pageNumber=${String(pageNumber).trim()}` +
      (search.slice(-1)[0] ? `&${search.slice(-1)}` : ""),
    {
      method: "GET",
      headers: headerTypeDefault(),
    }
  );

export const DEPARTMENT_ADD = (data) =>
  fetch(`${BASE_URL}departments/`, {
    method: "POST",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const DEPARTMENT_LENGTH = () =>
  fetch(`${BASE_URL}departments/length/get/`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const DEPARTMENT_ONCE = (id) =>
  fetch(`${BASE_URL}departments/${id}`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const DEPARTMENT_MODIFY = (id, data) =>
  fetch(`${BASE_URL}departments/${id}`, {
    method: "PATCH",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const DEPARTMENT_DELETE = (id) =>
  fetch(`${BASE_URL}departments/${id}`, {
    method: "DELETE",
    headers: headerTypeDefault(),
  });

export const MEASUREMENT_UNIT = () =>
  fetch(`${BASE_URL}measurement-units/`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const MEASUREMENT_UNITS_LENGTH = () =>
  fetch(`${BASE_URL}measurement-units/length/get/`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const MEASUREMENT_UNIT_PAGINATED = (
  pageNumber = 1,
  recordsPerPage = 15,
  search,
  fs
) =>
  fetch(
    `${BASE_URL}measurement-units/?recordsPerPage=${String(
      recordsPerPage
    ).trim()}&pageNumber=${String(pageNumber).trim()}` +
      (search ? `&${search[0]}=${search[1]}` : "") +
      (fs ? `&${fs}` : ""),
    {
      method: "GET",
      headers: headerTypeDefault(),
    }
  );

export const MEASUREMENT_UNIT_ONCE = (id) =>
  fetch(`${BASE_URL}measurement-units/${id}`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const MEASUREMENT_UNIT_ADD = (data) =>
  fetch(`${BASE_URL}measurement-units/`, {
    method: "POST",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const MEASUREMENT_UNIT_MODIFY = (id, data) =>
  fetch(`${BASE_URL}measurement-units/${id}`, {
    method: "PATCH",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const MEASUREMENT_UNIT_DELETE = (id) =>
  fetch(`${BASE_URL}measurement-units/${id}`, {
    method: "DELETE",
    headers: headerTypeDefault(),
  });

export const NEEDS = () =>
  fetch(`${BASE_URL}needs/`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const NEED_RESERVATION = (clearReservation, id) =>
  fetch(
    `${BASE_URL}needs/${clearReservation ? "clear" : ""}reserveforedit/${id}`,
    {
      method: "POST",
      headers: headerTypeDefault(),
      body: "{}",
    }
  );

export const NEEDS_AVIZO_TYPE = (needs) =>
  fetch(`${BASE_URL}needs/check-array-of-needs/`, {
    method: "POST",
    headers: headerTypeDefault(),
    body: JSON.stringify({ includedNeeds: needs }),
  });

export const NEEDS_CANCEL_AVIZO = (id, reason) =>
  fetch(`${BASE_URL}needs/remove-by-originator/${id}`, {
    method: "PATCH",
    headers: headerTypeDefault(),
    body: JSON.stringify(Object.assign(reason ? { reason: reason } : {})),
  });

export const EVENT_NOTIFICATION = (uuid, role) =>
  fetch(
    `${BASE_URL}notification/` +
      (uuid ? `?user=${uuid}` : "") +
      (role ? `&eq=role,${role}` : ""),
    {
      method: "GET",
      headers: headerTypeDefault(),
    }
  );

export const EVENT_NOTIFICATION_READ = (id) =>
  fetch(`${BASE_URL}notification/${id}`, {
    method: "PATCH",
    headers: headerTypeDefault(),
    body: JSON.stringify({ isRead: true }),
  });

export const NEEDS_AVIZO = (
  pageNumber = 1,
  recordsPerPage = 15,
  query,
  search
) =>
  fetch(
    `${BASE_URL}needs/my-avizo/needs/?${
      query ? `${query}&` : ""
    }&recordsPerPage=${String(recordsPerPage).trim()}&pageNumber=${String(
      pageNumber
    ).trim()}` + (search ? `&${search}` : ""),
    {
      method: "GET",
      headers: headerTypeDefault(),
    }
  );

export const NEEDS_AVIZO_MODIFY = (id, data) =>
  fetch(`${BASE_URL}needs/updateavizo/${id}`, {
    method: "PATCH",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const NEED_CANCEL = (id, reason) =>
  fetch(`${BASE_URL}needs/cancel/${id}`, {
    method: "PATCH",
    headers: headerTypeDefault(),
    body: JSON.stringify({ reason }),
  });

export const NEEDS_VERSIONS = (id) =>
  fetch(`${BASE_URL}needs/${id}/versions/`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const NEEDS_VERSION = (id, version) =>
  fetch(`${BASE_URL}needs/${id}/versions/${version}`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const NEEDS_LAST_VERSION = (id) =>
  fetch(`${BASE_URL}needs/version/${id}/last`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const NEEDS_DOWNLOAD_FILE = (id, file) =>
  fetch(true, `${BASE_URL}needs/download/${id}?fileName=${file}`, {
    method: "GET",
    headers: headerTypeDownload(),
  });

export const NEEDS_ONCE = (id) =>
  fetch(`${BASE_URL}needs/${id}`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const NEEDS_PAGINATED = (
  pageNumber = 1,
  recordsPerPage = 15,
  query,
  search
) =>
  fetch(
    `${BASE_URL}needs/?${query ? `${query}&` : ""}&recordsPerPage=${String(
      recordsPerPage
    ).trim()}&pageNumber=${String(pageNumber).trim()}` +
      (search ? `&${search}` : ""),
    {
      method: "GET",
      headers: headerTypeDefault(),
    }
  );
export const NEEDS_PRODUCTS = (
  pageNumber = 1,
  recordsPerPage = 15,
  ...filter
) => {
  return fetch(
    `${BASE_URL}needs/products?recordsPerPage=${String(
      recordsPerPage
    ).trim()}&pageNumber=${String(pageNumber).trim()}` +
      (filter.at(-1) ? `&${filter.at(-1)}` : ""),
    {
      method: "GET",
      headers: headerTypeDefault(),
    }
  );
};
export const NEEDS_LENGTH = () =>
  fetch(`${BASE_URL}needs/length/get/`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const NEEDS_UPLOAD = (id, src) =>
  fetch(`${BASE_URL}needs/upload/${id}`, {
    method: "POST",
    headers: setAuthToken({
      Accept: "*/*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Origin": "*",
      "conectx-userid-role": prepareUserRole(),
    }),
    body: src,
  });

export const NEEDS_ADD = (data) =>
  fetch(`${BASE_URL}needs`, {
    method: "POST",
    headers: headerTypeFormData(),
    body: data,
  });

export const NEEDS_CONEXE = (includedNeeds, needName) =>
  fetch(`${BASE_URL}needs/connected`, {
    method: "POST",
    headers: headerTypeDefault(),
    body: JSON.stringify({ includedNeeds, needName }),
  });

export const NEEDS_FROM_CONEXED = (id) =>
  fetch(`${BASE_URL}needs/parent/${id}`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const NEEDS_MODIFY = (id, data) =>
  fetch(`${BASE_URL}needs/${id}`, {
    method: "PATCH",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const NEEDS_REMOVE_CONNECTED = (id) =>
  fetch(`${BASE_URL}needs/connected/delete/${id}`, {
    method: "DELETE",
    headers: headerTypeDefault(),
  });

export const NEEDS_MODIFY_FORMDATA = (id, data) =>
  fetch(`${BASE_URL}needs/${id}`, {
    method: "PATCH",
    headers: headerTypeFormData(),
    body: data,
  });

export const NEEDS_DELETE = (id) =>
  fetch(`${BASE_URL}needs/${id}`, {
    method: "DELETE",
    headers: headerTypeDefault(),
  });

export const BUDGET = () =>
  fetch(`${BASE_URL}budget-source/`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const BUDGET_LENGTH = () =>
  fetch(`${BASE_URL}budget-source/length/get/`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const BUGET_PAGINATED = (
  pageNumber = 1,
  recordsPerPage = 15,
  ...search
) =>
  fetch(
    `${BASE_URL}budget-source/?recordsPerPage=${String(
      recordsPerPage
    ).trim()}&pageNumber=${String(pageNumber).trim()}` +
      (search.slice(-1)[0] ? `&${search.slice(-1)}` : ""),
    {
      method: "GET",
      headers: headerTypeDefault(),
    }
  );

export const BUDGET_ONCE = (id) =>
  fetch(`${BASE_URL}budget-source/${id}`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const BUDGET_ADD = (data) =>
  fetch(`${BASE_URL}budget-source/`, {
    method: "POST",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const BUDGET_MODIFY = (id, data) =>
  fetch(`${BASE_URL}budget-source/${id}`, {
    method: "PATCH",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const BUDGET_DELETE = (id) =>
  fetch(`${BASE_URL}budget-source/${id}`, {
    method: "DELETE",
    headers: headerTypeDefault(),
  });

export const CPV_CATEGORY = () =>
  fetch(`${BASE_URL}cpv-categories/`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const CPV_CATEGORY_PAGINATED = (
  pageNumber = 1,
  recordsPerPage = 15,
  cpvType,
  search,
  fs
) =>
  fetch(
    `${BASE_URL}cpv-categories/?recordsPerPage=${String(
      recordsPerPage
    ).trim()}&pageNumber=${String(
      pageNumber
    ).trim()}&orderFieldName=cpvCode&orderValue=ASC` +
      (cpvType ? `&cpvType=${cpvType}` : "") +
      (search ? `&${search[0]}=${search[1]}` : "") +
      (fs ? `&${fs}` : ""),
    {
      method: "GET",
      headers: headerTypeDefault(),
    }
  );

export const CPV_CATEGORY_ONCE = (id) =>
  fetch(`${BASE_URL}cpv-categories/${id}`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const CPV_CATEGORY_LENGTH = () =>
  fetch(`${BASE_URL}cpv-categories/length/get/`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const CPV_CATEGORY_ADD = (data) =>
  fetch(`${BASE_URL}cpv-categories/`, {
    method: "POST",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const CPV_CATEGORY_MODIFY = (id, data) =>
  fetch(`${BASE_URL}cpv-categories/${id}`, {
    method: "PATCH",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const CPV_CATEGORY_DELETE = (id) =>
  fetch(`${BASE_URL}cpv-categories/${id}`, {
    method: "DELETE",
    headers: headerTypeDefault(),
  });

export const PROCEDURE_TYPE = () =>
  fetch(`${BASE_URL}procedure-types`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const PROCEDURE_TYPE_LENGTH = () =>
  fetch(`${BASE_URL}procedure-types/length/get/`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const PROCEDURE_TYPE_PAGINATED = (
  pageNumber = 1,
  recordsPerPage = 15,
  ...search
) =>
  fetch(
    `${BASE_URL}procedure-types/?recordsPerPage=${String(
      recordsPerPage
    ).trim()}&pageNumber=${String(pageNumber).trim()}` +
      `&orderFieldName=name&orderValue=ASC` +
      (search.slice(-1)[0] ? `&${search.slice(-1)}` : ""),
    {
      method: "GET",
      headers: headerTypeDefault(),
    }
  );

export const PROCEDURE_TYPE_WITH_REGISTER = (...search) => {
  return fetch(
    `${BASE_URL}procedure-types/registrul-pragurilor?orderFieldName=name&orderValue=ASC` +
      (search ? `&${search}` : ""),
    {
      method: "GET",
      headers: headerTypeDefault(),
    }
  );
};
export const PROCEDURE_TYPE_MENU = () =>
  fetch(`${BASE_URL}procedure-types/menu`, {
    method: "GET",
    headers: headerTypeDefault(),
  });
export const PROCEDURE_TYPE_ONCE = (id) =>
  fetch(`${BASE_URL}procedure-types/${id}`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const PROCEDURE_TYPE_ADD = (data) =>
  fetch(`${BASE_URL}procedure-types/`, {
    method: "POST",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const PROCEDURE_TYPE_MODIFY = (id, data) =>
  fetch(`${BASE_URL}procedure-types/${id}`, {
    method: "PATCH",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const PROCEDURE_TYPE_DELETE = (id) =>
  fetch(`${BASE_URL}procedure-types/${id}`, {
    method: "DELETE",
    headers: headerTypeDefault(),
  });

export const REFERENCE_AUTOSAVE = (id, data) =>
  fetch(`${BASE_URL}referat-necesitate/autosave/${id}`, {
    method: "PATCH",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const REFERENCE_COMPLETE_CENTRALIZATION = (id, data) =>
  fetch(`${BASE_URL}referat-necesitate/complete-cetralization/${id}`, {
    method: "PATCH",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const REFERENCE_REQUEST_COMPLETE_CENTRALIZATION = (id, data) =>
  fetch(`${BASE_URL}referat-necesitate/request/complete-cetralization/${id}`, {
    method: "PATCH",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const REFERENCE_VERSIONS = (id) =>
  fetch(`${BASE_URL}referat-necesitate/versions/${id}`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const REFERENCE_EXTERNAL_SIGNATURE = (id) =>
  fetch(`${BASE_URL}referat-necesitate/sign/${id}`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const REFERENCE_PAGINATED = (
  pageNumber = 1,
  recordsPerPage = 15,
  ...filter
) =>
  fetch(
    `${BASE_URL}referat-necesitate/?recordsPerPage=${String(
      recordsPerPage
    ).trim()}&pageNumber=${String(pageNumber).trim()}` +
      (filter.at(-1) ? `&${filter.at(-1)}` : ""),
    {
      method: "GET",
      headers: headerTypeDefault(),
    }
  );
export const REFERENCE_ACHIZITII_DIRECTE = (
  pageNumber = 1,
  recordsPerPage = 15,
  ...filter
) =>
  fetch(
    `${BASE_URL}referat-necesitate/?recordsPerPage=${String(
      recordsPerPage
    ).trim()}&pageNumber=${String(
      pageNumber
    ).trim()}&neq=paap,null&registrulPragurilor.achizitionModality=Achiziție directă` +
      (filter.at(-1) ? `&${filter.at(-1)}` : ""),
    {
      method: "GET",
      headers: headerTypeDefault(),
    }
  );
export const REFERENCE_PROCEDURI_SIMPLIFICATE = (
  pageNumber = 1,
  recordsPerPage = 15,
  ...filter
) =>
  fetch(
    `${BASE_URL}referat-necesitate/?recordsPerPage=${String(
      recordsPerPage
    ).trim()}&pageNumber=${String(
      pageNumber
    ).trim()}&neq=paap,null&registrulPragurilor.achizitionModality=Acord cadru` +
      (filter.at(-1) ? `&${filter.at(-1)}` : ""),
    {
      method: "GET",
      headers: headerTypeDefault(),
    }
  );
export const REFERENCE_LICITATII_PUBLICE = (
  pageNumber = 1,
  recordsPerPage = 15,
  ...filter
) =>
  fetch(
    `${BASE_URL}referat-necesitate/?recordsPerPage=${String(
      recordsPerPage
    ).trim()}&pageNumber=${String(
      pageNumber
    ).trim()}&neq=paap,null&registrulPragurilor.achizitionModality=Achiziție public` +
      (filter.at(-1) ? `&${filter.at(-1)}` : ""),
    {
      method: "GET",
      headers: headerTypeDefault(),
    }
  );

export const REFERENCE_ONCE = (id) =>
  fetch(`${BASE_URL}referat-necesitate/${id}`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const REFERENCE_GET_PDF = (id) =>
  fetch(true, `${BASE_URL}referat-necesitate/getpdf/${id}`, {
    method: "GET",
    headers: headerTypeDownload(),
  });

export const REFERENCE_GET_ANEXE_FILE = (id, file) =>
  fetch(
    true,
    `${BASE_URL}referat-necesitate/download/annexe/${id}?fileName=${file}`,
    {
      method: "GET",
      headers: headerTypeDownload(),
    }
  );

export const REFERENCE_GENERATE_PDF = (data) =>
  fetch(true, `${BASE_URL}referat-necesitate/generatepdfforversioning`, {
    method: "POST",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const REFERENCE_RESERVATION = (clearReservation, id) =>
  fetch(
    `${BASE_URL}referat-necesitate/${
      clearReservation ? "clear" : ""
    }reserveforedit/${id}`,
    {
      method: "POST",
      headers: headerTypeDefault(),
      body: "{}",
    }
  );

export const REFERENCE_GENERATE_PART_PDF = (id, needId, filename) =>
  fetch(
    `${BASE_URL}referat-necesitate/generatepdffromattachementandsavetopdf/${id}?fileName=${filename}&needId=${needId}`,
    {
      method: "GET",
      headers: headerTypeDownload(),
    }
  );

export const REFERENCE_SAVE_PDF = (id) =>
  fetch(`${BASE_URL}referat-necesitate/generatenrandsavetopdf/${id}`, {
    method: "GET",
    headers: headerTypeDownload(),
  });

export const REFERENCE_ADD = (data) =>
  fetch(`${BASE_URL}referat-necesitate/`, {
    method: "POST",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const REFERENCE_ADD_DYNAMIC = (data) =>
  fetch(`${BASE_URL}referat-necesitate/nrdinamicfield/`, {
    method: "POST",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const REFERENCE_CHANGE_STATUS = (id, data) =>
  fetch(`${BASE_URL}referat-necesitate/finish/${id}`, {
    method: "POST",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const REFERENCE_MODIFY_DYNAMIC = (id, data) =>
  fetch(`${BASE_URL}referat-necesitate/nrdinamicfield/${id}`, {
    method: "PATCH",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const REFERENCE_DELETE_DYNAMIC = (id) =>
  fetch(`${BASE_URL}referat-necesitate/nrdinamicfield/${id}`, {
    method: "DELETE",
    headers: headerTypeDefault(),
  });

export const REFERENCE_DELETE_PENDING = (id) =>
  fetch(`${BASE_URL}referat-necesitate/pending/${id}`, {
    method: "DELETE",
    headers: headerTypeDefault(),
  });
export const REFERENCE_DELETE_PAAP = (data) =>
  fetch(`${BASE_URL}referat-necesitate/paap`, {
    method: "DELETE",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });
export const REFERENCE_MODIFY = (id, data) =>
  fetch(`${BASE_URL}referat-necesitate/${id}`, {
    method: "PATCH",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const REFERENCE_LAST_VERSION = (id) =>
  fetch(`${BASE_URL}referat-necesitate/version/${id}/last`, {
    method: "GET",
    headers: headerTypeDefault(),
  });

export const PROJECTS = {
  get: (pageNumber = 1, recordsPerPage = 15, ...filter) =>
    fetch(
      `${BASE_URL}projects/?recordsPerPage=${String(
        recordsPerPage
      ).trim()}&pageNumber=${String(pageNumber).trim()}` +
        (filter.at(-1) ? `&${filter.at(-1)}` : ""),
      {
        method: "GET",
        headers: headerTypeDefault(),
      }
    ),
  getOne: (id) =>
    fetch(`${BASE_URL}projects/${id}`, {
      method: "GET",
      headers: headerTypeDefault(),
    }),
  download: (id, file) =>
    fetch(true, `${BASE_URL}projects/download/${id}?fileName=${file}`, {
      method: "GET",
      headers: headerTypeDownload(),
    }),
  add: (data) =>
    fetch(`${BASE_URL}projects/`, {
      method: "POST",
      headers: headerTypeFormData(),
      body: data,
    }),
  edit: (id, data) =>
    fetch(`${BASE_URL}projects/${id}`, {
      method: "PATCH",
      headers: headerTypeFormData(),
      body: data,
    }),
  delete: (id) =>
    fetch(`${BASE_URL}projects/${id}`, {
      method: "DELETE",
      headers: headerTypeDefault(),
    }),
};

export const FINANCING = {
  get: (pageNumber = 1, recordsPerPage = 15, search, ...filter) =>
    fetch(
      `${BASE_URL}projectfinsource/?orderFieldName=sourceName&orderValue=ASC&recordsPerPage=${String(
        recordsPerPage
      ).trim()}&pageNumber=${String(pageNumber).trim()}` +
        (search ? `&${search[0]}=${search[1]}` : "") +
        (filter.at(-1) ? `&${filter.at(-1)}` : ""),
      {
        method: "GET",
        headers: headerTypeDefault(),
      }
    ),
  add: (data) =>
    fetch(`${BASE_URL}projectfinsource/`, {
      method: "POST",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  edit: (id, data) =>
    fetch(`${BASE_URL}projectfinsource/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  delete: (id) =>
    fetch(`${BASE_URL}projectfinsource/${id}`, {
      method: "DELETE",
      headers: headerTypeDefault(),
    }),
};

export const GET_BUDGET_REPORTS = (
  pageNumber = 1,
  recordsPerPage = 15,
  ...filter
) =>
  fetch(
    `${BASE_URL}projectfinsource/reference-report?recordsPerPage=${String(
      recordsPerPage
    ).trim()}&pageNumber=${String(pageNumber).trim()}` +
      (filter.at(-1) ? `&${filter.at(-1)}` : ""),
    {
      method: "GET",
      headers: headerTypeDefault(),
    }
  );
export const DOWNLOAD_BUDGET_REPORTS = () =>
  fetch(true, `${BASE_URL}projectfinsource/reference-report/download`, {
    method: "GET",
    headers: headerTypeDownload(),
  });

export const CONTRACT = {
  get: (pageNumber = 1, recordsPerPage = 15, search, ...filter) =>
    fetch(
      `${BASE_URL}contracttypes/?recordsPerPage=${String(
        recordsPerPage
      ).trim()}&pageNumber=${String(pageNumber).trim()}` +
        (search ? `&${search[0]}=${search[1]}` : "") +
        (filter.at(-1) ? `&${filter.at(-1)}` : ""),
      {
        method: "GET",
        headers: headerTypeDefault(),
      }
    ),
  add: (data) =>
    fetch(`${BASE_URL}contracttypes/`, {
      method: "POST",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  edit: (id, data) =>
    fetch(`${BASE_URL}contracttypes/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  delete: (id) =>
    fetch(`${BASE_URL}contracttypes/${id}`, {
      method: "DELETE",
      headers: headerTypeDefault(),
    }),
};

export const EXPENSES = {
  get: (pageNumber = 1, recordsPerPage = 15, ...filter) =>
    fetch(
      `${BASE_URL}projectexpenses/?recordsPerPage=${String(
        recordsPerPage
      ).trim()}&pageNumber=${String(pageNumber).trim()}` +
        (filter.at(-1) ? `&${filter.at(-1)}` : ""),
      {
        method: "GET",
        headers: headerTypeDefault(),
      }
    ),
  download: (id) =>
    fetch(true, `${BASE_URL}projectexpenses/download/${id}`, {
      method: "GET",
      headers: headerTypeDownload(),
    }),
  add: (data) =>
    fetch(`${BASE_URL}projectexpenses/`, {
      method: "POST",
      headers: headerTypeFormData(),
      body: data,
    }),
  edit: (id, data) =>
    fetch(`${BASE_URL}projectexpenses/${id}`, {
      method: "PATCH",
      headers: headerTypeFormData(),
      body: data,
    }),
  delete: (id) =>
    fetch(`${BASE_URL}projectexpenses/${id}`, {
      method: "DELETE",
      headers: headerTypeDefault(),
    }),
};

export const EXPENSES_PAYMENTS = {
  get: (pageNumber = 1, recordsPerPage = 15, ...filter) =>
    fetch(
      `${BASE_URL}projectexpensepayments/?recordsPerPage=${String(
        recordsPerPage
      ).trim()}&pageNumber=${String(pageNumber).trim()}` +
        (filter.at(-1) ? `&${filter.at(-1)}` : ""),
      {
        method: "GET",
        headers: headerTypeDefault(),
      }
    ),
  add: (data) =>
    fetch(`${BASE_URL}projectexpensepayments/`, {
      method: "POST",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  edit: (id, data) =>
    fetch(`${BASE_URL}projectexpensepayments/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  delete: (id) =>
    fetch(`${BASE_URL}projectexpensepayments/${id}`, {
      method: "DELETE",
      headers: headerTypeDefault(),
    }),
};

export const PROJECTS_AUTH = {
  get: (pageNumber = 1, recordsPerPage = 15, ...filter) =>
    fetch(
      `${BASE_URL}projectauthorization/?recordsPerPage=${String(
        recordsPerPage
      ).trim()}&pageNumber=${String(pageNumber).trim()}` +
        (filter.at(-1) ? `&${filter.at(-1)}` : ""),
      {
        method: "GET",
        headers: headerTypeDefault(),
      }
    ),
  add: (data) =>
    fetch(`${BASE_URL}projectauthorization/`, {
      method: "POST",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  edit: (id, data) =>
    fetch(`${BASE_URL}projectauthorization/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  delete: (id) =>
    fetch(`${BASE_URL}projectauthorization/${id}`, {
      method: "DELETE",
      headers: headerTypeDefault(),
    }),
};

export const PAAP = {
  get: (pageNumber = 1, recordsPerPage = 15, ...filter) =>
    fetch(
      `${BASE_URL}paap/all/?recordsPerPage=${String(
        recordsPerPage
      ).trim()}&pageNumber=${String(pageNumber).trim()}` +
        (filter.at(-1) ? `&${filter.at(-1)}` : ""),
      {
        method: "GET",
        headers: headerTypeDefault(),
      }
    ),
  getPdf: (id) =>
    fetch(true, `${BASE_URL}paap/pdf/${id}`, {
      method: "GET",
      headers: headerTypeDownload(),
    }),
  getTopics: (
    paapId,
    referenceId,
    pageNumber = 1,
    recordsPerPage = 25,
    ...filter
  ) =>
    fetch(
      `${BASE_URL}paap/${paapId}/topics/referate/${referenceId}/?recordsPerPage=${String(
        recordsPerPage
      ).trim()}&pageNumber=${String(pageNumber).trim()}` +
        (filter.at(-1) ? `&${filter.at(-1)}` : ""),
      {
        method: "GET",
        headers: headerTypeDefault(),
      }
    ),
  getComments: (topicId, pageNumber = 1, recordsPerPage = 25, ...filter) =>
    fetch(
      `${BASE_URL}paap/topic/${topicId}/messages/?recordsPerPage=${String(
        recordsPerPage
      ).trim()}&pageNumber=${String(pageNumber).trim()}` +
        (filter.at(-1) ? `&${filter.at(-1)}` : ""),
      {
        method: "GET",
        headers: headerTypeDefault(),
      }
    ),
  getFromToken: (token) =>
    fetch(`${BASE_URL}paap/token/${token}`, {
      method: "GET",
      headers: headerTypeEmpty(),
    }),
  getOne: (id, centralizationId) =>
    fetch(`${BASE_URL}paap/${id}/${centralizationId}`, {
      method: "GET",
      headers: headerTypeDefault(),
    }),
  getVersions: (id) =>
    fetch(`${BASE_URL}paap/versions/${id}`, {
      method: "GET",
      headers: headerTypeDefault(),
    }),
  add: (data) =>
    fetch(`${BASE_URL}paap/`, {
      method: "POST",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  addTopic: (data) =>
    fetch(`${BASE_URL}paap/newtopic/`, {
      method: "POST",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  addComment: (data) =>
    fetch(`${BASE_URL}paap/newmessage/`, {
      method: "POST",
      headers: headerTypeFormData(),
      body: data,
    }),
  download: (mid, tid, rid, filename) =>
    fetch(true, `${BASE_URL}paap/message/${mid}/topic/${tid}/referate/${rid}`, {
      method: "POST",
      headers: headerTypeDefault(),
      body: JSON.stringify({
        filename,
      }),
    }),
  edit: (id, data) =>
    fetch(`${BASE_URL}paap/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  delete: (id) =>
    fetch(`${BASE_URL}paap/${id}`, {
      method: "DELETE",
      headers: headerTypeDefault(),
    }),
  requestApporove: (id) =>
    fetch(`${BASE_URL}paap/approve/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
    }),
};

export const PAAP_VOTE = {
  getConsiliers: (institutionId) =>
    fetch(
      `${BASE_URL}users/institution/${institutionId}?isConsilier=1&userRoleStatus=active`,
      {
        method: "GET",
        headers: headerTypeDefault(),
      }
    ),
  startVote: (data) =>
    fetch(`${BASE_URL}nrvote/`, {
      method: "POST",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  getVote: (voteId) =>
    fetch(`${BASE_URL}nrvote/?eq=needReferate,${voteId}`, {
      method: "GET",
      headers: headerTypeDefault(),
    }),
  deleteVote: (voteId) =>
    fetch(`${BASE_URL}nrvote/${voteId}`, {
      method: "DELETE",
      headers: headerTypeDefault(),
    }),
  setVote: (voteId, participantId, data) =>
    fetch(`${BASE_URL}nrvote/vote/${voteId}/participant/${participantId}/`, {
      method: "PATCH",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
};

export const BUGETARE = {
  get: (pageNumber = 1, recordsPerPage = 15, type, search, fs) =>
    fetch(
      `${BASE_URL}budget-clasification/?recordsPerPage=${String(
        recordsPerPage
      ).trim()}&pageNumber=${String(pageNumber).trim()}` +
        (type ? `&type=${type}` : "") +
        (search ? `&${search[0]}=${search[1]}` : "") +
        (fs ? `&${fs}` : ""),
      {
        method: "GET",
        headers: headerTypeDefault(),
      }
    ),
  getOne: (id) =>
    fetch(`${BASE_URL}budget-clasification/${id}`, {
      method: "GET",
      headers: headerTypeDefault(),
    }),
  add: (data) =>
    fetch(`${BASE_URL}budget-clasification/`, {
      method: "POST",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  edit: (id, data) =>
    fetch(`${BASE_URL}budget-clasification/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  delete: (id) =>
    fetch(`${BASE_URL}budget-clasification/${id}`, {
      method: "DELETE",
      headers: headerTypeDefault(),
    }),
};

export const INSTITUTION_OBJECTIVES = {
  get: (pageNumber = 1, recordsPerPage = 15, ...filter) =>
    fetch(
      `${BASE_URL}institution-objectives/?recordsPerPage=${String(
        recordsPerPage
      ).trim()}&pageNumber=${String(pageNumber).trim()}` +
        (filter.at(-1) ? `&${filter.at(-1)}` : ""),
      {
        method: "GET",
        headers: headerTypeDefault(),
      }
    ),
  add: (data) =>
    fetch(`${BASE_URL}institution-objectives/`, {
      method: "POST",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  edit: (id, data) =>
    fetch(`${BASE_URL}institution-objectives/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  changeStatus: (id, data) =>
    fetch(`${BASE_URL}institution-objectives/status/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
};

export const INSTITUTION_PROVIDERS = {
  get: (pageNumber = 1, recordsPerPage = 15, ...filter) =>
    fetch(
      `${BASE_URL}institution-providers/?recordsPerPage=${String(
        recordsPerPage
      ).trim()}&pageNumber=${String(pageNumber).trim()}` +
        (filter.at(-1) ? `&${filter.at(-1)}` : ""),
      {
        method: "GET",
        headers: headerTypeDefault(),
      }
    ),
  add: (data) =>
    fetch(`${BASE_URL}institution-providers/`, {
      method: "POST",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  edit: (id, data) =>
    fetch(`${BASE_URL}institution-providers/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  delete: (id) =>
    fetch(`${BASE_URL}institution-providers/${id}`, {
      method: "DELETE",
      headers: headerTypeDefault(),
    }),
  getByCui: (cui) =>
    fetch(`${BASE_URL}institution-providers/${cui}`, {
      method: "GET",
      headers: headerTypeDefault(),
    }),
};

export const ANNUAL_INSTITUTION_OBJECTIVES = {
  get: (pageNumber = 1, recordsPerPage = 15, ...filter) =>
    fetch(
      `${BASE_URL}annual-institution-objectives/?recordsPerPage=${String(
        recordsPerPage
      ).trim()}&pageNumber=${String(pageNumber).trim()}` +
        (filter.at(-1) ? `&${filter.at(-1)}` : ""),
      {
        method: "GET",
        headers: headerTypeDefault(),
      }
    ),
  add: (data) =>
    fetch(`${BASE_URL}annual-institution-objectives/`, {
      method: "POST",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  edit: (id, data) =>
    fetch(`${BASE_URL}annual-institution-objectives/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  checkIfExist: (institutionObjectiveId, year) =>
    fetch(
      `${BASE_URL}annual-institution-objectives/check?institutionObjectiveId=${institutionObjectiveId}&year=${year}`,
      {
        method: "GET",
        headers: headerTypeDefault(),
      }
    ),
  delete: (id) =>
    fetch(`${BASE_URL}annual-institution-objectives/${id}`, {
      method: "DELETE",
      headers: headerTypeDefault(),
    }),
};
export const RN_OBIECTIVE = {
  get: (pageNumber = 1, recordsPerPage = 15, ...filter) =>
    fetch(
      `${BASE_URL}rn-anual-nomenclature/?recordsPerPage=${String(
        recordsPerPage
      ).trim()}&pageNumber=${String(pageNumber).trim()}` +
        (filter.at(-1) ? `&${filter.at(-1)}` : ""),
      {
        method: "GET",
        headers: headerTypeDefault(),
      }
    ),
  getOne: (id) =>
    fetch(`${BASE_URL}rn-anual-nomenclature/${id}`, {
      method: "GET",
      headers: headerTypeDefault(),
    }),
  add: (data) =>
    fetch(`${BASE_URL}rn-anual-nomenclature/`, {
      method: "POST",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  edit: (id, data) =>
    fetch(`${BASE_URL}rn-anual-nomenclature/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  delete: (id) =>
    fetch(`${BASE_URL}rn-anual-nomenclature/${id}`, {
      method: "DELETE",
      headers: headerTypeDefault(),
    }),
};
export const ACHIZITII = {
  get: (pageNumber = 1, recordsPerPage = 15, ...filter) =>
    fetch(
      `${BASE_URL}achizition-category/?recordsPerPage=${String(
        recordsPerPage
      ).trim()}&pageNumber=${String(pageNumber).trim()}` +
        (filter.at(-1) ? `&${filter.at(-1)}` : ""),
      {
        method: "GET",
        headers: headerTypeDefault(),
      }
    ),
  getOne: (id) =>
    fetch(`${BASE_URL}achizition-category/${id}`, {
      method: "GET",
      headers: headerTypeDefault(),
    }),
  canDelete: (id) =>
    fetch(`${BASE_URL}achizition-category/candelete/${id}`, {
      method: "GET",
      headers: headerTypeDefault(),
    }),
  add: (data) =>
    fetch(`${BASE_URL}achizition-category/`, {
      method: "POST",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  edit: (id, data) =>
    fetch(`${BASE_URL}achizition-category/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  delete: (id) =>
    fetch(`${BASE_URL}achizition-category/${id}`, {
      method: "DELETE",
      headers: headerTypeDefault(),
    }),
};

export const GET_PAAP_CENTRALIZATION_PAGINATED = (
  pageNumber = 1,
  recordsPerPage = 15,
  ...filter
) => {
  return fetch(
    `${BASE_URL}paap/centralization/all/?recordsPerPage=${String(
      recordsPerPage
    ).trim()}&pageNumber=${String(pageNumber).trim()}` +
      (filter.at(-1) ? `&${filter.at(-1)}` : ""),
    {
      method: "GET",
      headers: headerTypeDefault(),
    }
  );
};

export const PAAP_CENTRALIZATION_EDIT = (id, data) =>
  fetch(`${BASE_URL}paap-centralization/${id}`, {
    method: "PATCH",
    headers: headerTypeDefault(),
    body: JSON.stringify(data),
  });

export const GET_PAAP_CENTRALIZATION_REPORTS = (
  pageNumber = 1,
  recordsPerPage = 15,
  ...filter
) => {
  return fetch(
    `${BASE_URL}paap-centralization/reports?recordsPerPage=${String(
      recordsPerPage
    ).trim()}&pageNumber=${String(pageNumber).trim()}` +
      (filter.at(-1) ? `&${filter.at(-1)}` : ""),
    {
      method: "GET",
      headers: headerTypeDefault(),
    }
  );
};

export const PAAP_CENTRALIZATION_GENERATE = () =>
  fetch(true, `${BASE_URL}paap-centralization/reports/download`, {
    method: "GET",
    headers: headerTypeDownload(),
  });

export const ACQUISITIONS = {
  get: (pageNumber = 1, recordsPerPage = 15, ...filter) =>
    fetch(
      `${BASE_URL}acquisitions/?recordsPerPage=${String(
        recordsPerPage
      ).trim()}&pageNumber=${String(pageNumber).trim()}` +
        (filter.at(-1) ? `&${filter.at(-1)}` : ""),
      {
        method: "GET",
        headers: headerTypeDefault(),
      }
    ),
  getOne: (id) =>
    fetch(`${BASE_URL}acquisitions/${id}`, {
      method: "GET",
      headers: headerTypeDefault(),
    }),
  add: (data) =>
    fetch(`${BASE_URL}acquisitions/`, {
      method: "POST",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  edit: (id, data) =>
    fetch(`${BASE_URL}acquisitions/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  delete: (id) =>
    fetch(`${BASE_URL}acquisitions/${id}`, {
      method: "DELETE",
      headers: headerTypeDefault(),
    }),
  generate: (id) =>
    fetch(`${BASE_URL}acquisitions/generate/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
    }),
  getPdf: (id) =>
    fetch(true, `${BASE_URL}acquisitions/pdf/${id}`, {
      method: "GET",
      headers: headerTypeDownload(),
    }),
  requestApprove: (id) =>
    fetch(`${BASE_URL}acquisitions/request-approve/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
    }),
  approve: (id, data) =>
    fetch(`${BASE_URL}acquisitions/approve/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  closeAcquisition: (id, data) =>
    fetch(`${BASE_URL}acquisitions/close/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  addDocuments: (id, data) =>
    fetch(`${BASE_URL}acquisitions/${id}/documents`, {
      method: "PATCH",
      headers: headerTypeFormData(),
      body: data,
    }),
  getDocument: (id, name) => {
    return fetch(
      true,
      `${BASE_URL}acquisitions/${id}/document/?fileName=${Buffer.from(
        name
      ).toString("base64")}`,
      {
        method: "GET",
        headers: headerTypeDownload(),
      }
    );
  },
  deleteDocument: (id, data) =>
    fetch(`${BASE_URL}acquisitions/${id}/document`, {
      method: "DELETE",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
};

export const ACQUISITION_PRODUCTS_APPROVED = (
  pageNumber = 1,
  recordsPerPage = 15,
  ...filter
) =>
  fetch(
    `${BASE_URL}acquisition-products/approved?recordsPerPage=${String(
      recordsPerPage
    ).trim()}&pageNumber=${String(pageNumber).trim()}` +
      (filter.at(-1) ? `&${filter.at(-1)}` : ""),
    {
      method: "GET",
      headers: headerTypeDefault(),
    }
  );

export const ACQUISITION_PRODUCTS_GENERATE = () =>
  fetch(true, `${BASE_URL}acquisition-products/approved/download`, {
    method: "GET",
    headers: headerTypeDownload(),
  });

export const ACQUISITION_PRODUCTS = {
  get: (pageNumber = 1, recordsPerPage = 15, ...filter) =>
    fetch(
      `${BASE_URL}acquisition-products/?recordsPerPage=${String(
        recordsPerPage
      ).trim()}&pageNumber=${String(pageNumber).trim()}` +
        (filter.at(-1) ? `&${filter.at(-1)}` : ""),
      {
        method: "GET",
        headers: headerTypeDefault(),
      }
    ),

  getOne: (id) =>
    fetch(`${BASE_URL}acquisition-products/${id}`, {
      method: "GET",
      headers: headerTypeDefault(),
    }),
  add: (data) =>
    fetch(`${BASE_URL}acquisition-products/`, {
      method: "POST",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  edit: (id, data) =>
    fetch(`${BASE_URL}acquisition-products/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  delete: (id) =>
    fetch(`${BASE_URL}acquisition-products/${id}`, {
      method: "DELETE",
      headers: headerTypeDefault(),
    }),
};
export const VERBAL_PROCESS = {
  get: (pageNumber = 1, recordsPerPage = 15, ...filter) =>
    fetch(
      `${BASE_URL}verbal-process/?recordsPerPage=${String(
        recordsPerPage
      ).trim()}&pageNumber=${String(pageNumber).trim()}` +
        (filter.at(-1) ? `&${filter.at(-1)}` : ""),
      {
        method: "GET",
        headers: headerTypeDefault(),
      }
    ),
  getOne: (id) =>
    fetch(`${BASE_URL}verbal-process/${id}`, {
      method: "GET",
      headers: headerTypeDefault(),
    }),
  add: (data) =>
    fetch(`${BASE_URL}verbal-process/`, {
      method: "POST",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  edit: (id, data) =>
    fetch(`${BASE_URL}verbal-process/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  delete: (id) =>
    fetch(`${BASE_URL}verbal-process/${id}`, {
      method: "DELETE",
      headers: headerTypeDefault(),
    }),
  createAcquisition: (id, data) =>
    fetch(`${BASE_URL}verbal-process/${id}/acquisition`, {
      method: "POST",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  getPdf: (id) =>
    fetch(true, `${BASE_URL}verbal-process/pdf/${id}`, {
      method: "GET",
      headers: headerTypeDownload(),
    }),
  requestApprove: (id) =>
    fetch(`${BASE_URL}verbal-process/request-approve/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
    }),
  approve: (id, data) =>
    fetch(`${BASE_URL}verbal-process/approve/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),

  getProviders: (id) =>
    fetch(`${BASE_URL}verbal-process/${id}/providers`, {
      method: "GET",
      headers: headerTypeDefault(),
    }),
  deleteOfferAnnexe: (verbalProcess, id, data) =>
    fetch(`${BASE_URL}verbal-process/offer-annexe/${verbalProcess}/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  getOfferAnnexe: (verbalProcess, name) =>
    fetch(
      true,
      `${BASE_URL}verbal-process/offer-annexe/${verbalProcess}?fileName=${name}`,
      {
        method: "GET",
        headers: headerTypeDownload(),
      }
    ),
};

export const ANNOUNCEMENT = {
  get: (pageNumber = 1, recordsPerPage = 15, ...filter) =>
    fetch(
      `${BASE_URL}announcements/?recordsPerPage=${String(
        recordsPerPage
      ).trim()}&pageNumber=${String(pageNumber).trim()}` +
        (filter.at(-1) ? `&${filter.at(-1)}` : ""),
      {
        method: "GET",
        headers: headerTypeDefault(),
      }
    ),
  getOne: (id) =>
    fetch(`${BASE_URL}announcements/${id}`, {
      method: "GET",
      headers: headerTypeDefault(),
    }),
  add: (data) =>
    fetch(`${BASE_URL}announcements/`, {
      method: "POST",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  edit: (id, data) =>
    fetch(`${BASE_URL}announcements/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  delete: (id) =>
    fetch(`${BASE_URL}announcements/${id}`, {
      method: "DELETE",
      headers: headerTypeDefault(),
    }),
  createAcquisition: (id, data) =>
    fetch(`${BASE_URL}announcements/${id}/acquisition`, {
      method: "POST",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  getPdf: (id) =>
    fetch(true, `${BASE_URL}announcements/pdf/${id}`, {
      method: "GET",
      headers: headerTypeDownload(),
    }),
  requestApprove: (id) =>
    fetch(`${BASE_URL}announcements/request-approve/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
    }),
  approve: (id, data) =>
    fetch(`${BASE_URL}announcements/approve/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
};

export const PROCEDURE_DOCUMENTS = {
  createProcedureDocument: (data) =>
    fetch(`${BASE_URL}procedure-document`, {
      method: "POST",
      headers: headerTypeFormData(),
      body: data,
    }),
  addDocuments: (id, data) =>
    fetch(`${BASE_URL}procedure-document/${id}`, {
      method: "PATCH",
      headers: headerTypeFormData(),
      body: data,
    }),
  close: (id) =>
    fetch(`${BASE_URL}procedure-document/${id}/close`, {
      method: "PATCH",
      headers: headerTypeDefault(),
    }),
  getDocument: (id, name) => {
    return fetch(
      true,
      `${BASE_URL}procedure-document/${id}/document/?fileName=${Buffer.from(
        name
      ).toString("base64")}`,
      {
        method: "GET",
        headers: headerTypeDownload(),
      }
    );
  },
  deleteDocument: (id, data) =>
    fetch(`${BASE_URL}procedure-document/${id}/document`, {
      method: "DELETE",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
};

export const ORGANIZATIONAL_STRUCTURE_TYPE = {
  get: (pageNumber = 1, recordsPerPage = 15, ...filter) =>
    fetch(
      `${BASE_URL}organizational-structure-type/?recordsPerPage=${String(
        recordsPerPage
      ).trim()}&pageNumber=${String(pageNumber).trim()}` +
        (filter.at(-1) ? `&${filter.at(-1)}` : ""),
      {
        method: "GET",
        headers: headerTypeDefault(),
      }
    ),
  getOne: (id) =>
    fetch(`${BASE_URL}organizational-structure-type/${id}`, {
      method: "GET",
      headers: headerTypeDefault(),
    }),
  add: (data) =>
    fetch(`${BASE_URL}organizational-structure-type/`, {
      method: "POST",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  edit: (id, data) =>
    fetch(`${BASE_URL}organizational-structure-type/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  delete: (id) =>
    fetch(`${BASE_URL}organizational-structure-type/${id}`, {
      method: "DELETE",
      headers: headerTypeDefault(),
    }),
};
export const INSTITUTION_TYPE = {
  get: (pageNumber = 1, recordsPerPage = 15, ...filter) =>
    fetch(
      `${BASE_URL}institution-type/?recordsPerPage=${String(
        recordsPerPage
      ).trim()}&pageNumber=${String(pageNumber).trim()}` +
        (filter.at(-1) ? `&${filter.at(-1)}` : ""),
      {
        method: "GET",
        headers: headerTypeDefault(),
      }
    ),
  getOne: (id) =>
    fetch(`${BASE_URL}institution-type/${id}`, {
      method: "GET",
      headers: headerTypeDefault(),
    }),
  add: (data) =>
    fetch(`${BASE_URL}institution-type/`, {
      method: "POST",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  edit: (id, data) =>
    fetch(`${BASE_URL}institution-type/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  delete: (id) =>
    fetch(`${BASE_URL}institution-type/${id}`, {
      method: "DELETE",
      headers: headerTypeDefault(),
    }),
};

export const CREDIT_ORDERER_TYPE = {
  get: (pageNumber = 1, recordsPerPage = 15, ...filter) =>
    fetch(
      `${BASE_URL}credit-orderer-type/?recordsPerPage=${String(
        recordsPerPage
      ).trim()}&pageNumber=${String(pageNumber).trim()}` +
        (filter.at(-1) ? `&${filter.at(-1)}` : ""),
      {
        method: "GET",
        headers: headerTypeDefault(),
      }
    ),
  getOne: (id) =>
    fetch(`${BASE_URL}credit-orderer-type/${id}`, {
      method: "GET",
      headers: headerTypeDefault(),
    }),
  add: (data) =>
    fetch(`${BASE_URL}credit-orderer-type/`, {
      method: "POST",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  edit: (id, data) =>
    fetch(`${BASE_URL}credit-orderer-type/${id}`, {
      method: "PATCH",
      headers: headerTypeDefault(),
      body: JSON.stringify(data),
    }),
  delete: (id) =>
    fetch(`${BASE_URL}credit-orderer-type/${id}`, {
      method: "DELETE",
      headers: headerTypeDefault(),
    }),
};

import { mapMutations } from "vuex";
import { Calc } from "calc-js";
import needStatusesMap from "@/Maps/needStatusesMap.js";

const numeral = require("numeral");
const safetyCalculator = (method) => (...nums) => {
  try {
    if (!nums.length) return NaN;
    nums = nums.flat();
    const x = new Calc(parseFloat(nums[0]) || 0);
    if (!method || !x[method]) return NaN;
    nums
      .slice(1)
      .forEach((e) =>
        isNaN(parseFloat(e)) ? false : x[method](parseFloat(e))
      );
    return x.finish();
  } catch (err) {
    console.error("Calculation limit has been exceeded. #986412");
    return NaN;
  }
};
async function isBase64UrlImage(base64String) {
  let image = new Image();
  image.src = base64String;
  return await new Promise((resolve) => {
    image.onload = function() {
      if (image.height === 0 || image.width === 0) {
        resolve(false);
        return;
      }
      resolve(true);
    };
    image.onerror = () => {
      resolve(false);
    };
  });
}

let safeLoadTimer = null;

export default {
  computed: {
    allUserRoles() {
      return {
        superadmin: "superadmin",
        admin: "admin",
        admin_institutie: "admin_institutie",
        sef_institutie: "sef_institutie",
        supervisor: "supervisor",
        functionar: "functionar",
        responsabil_achizitii: "responsabil_achizitii",
        supervizor_achizitii: "supervizor_achizitii",
        responsabil_achizitii_extern: "responsabil_achizitii_extern",
        responsabil_buget: "responsabil_buget",
      };
    },
  },
  methods: {
    ...mapMutations(["setUserRole"]),
    _Multiply: safetyCalculator("multiply"),
    _Sum: safetyCalculator("sum"),
    _Divide: safetyCalculator("divide"),
    _Minus: safetyCalculator("minus"),
    setLoad(load) {
      if (!load) clearInterval(safeLoadTimer);
      document.body?.classList[load ? "add" : "remove"]("__Loading");
    },
    safeCallFn(fn, ...params) {
      if (typeof fn === "function") {
        return fn.call(null, ...params);
      }
    },
    setSafeLoad(loadTime = 5000) {
      this.setLoad(true);
      clearInterval(safeLoadTimer);
      safeLoadTimer = setTimeout(this.setLoad, loadTime, false);
    },
    simulateLoad(callback, timeout = 500) {
      this.setLoad(true);

      setTimeout(() => {
        this.setLoad();
        if (typeof callback == "function") callback();
      }, timeout);
    },
    JSONvalide(text) {
      if (!text || !this.isString(text)) return false;
      return !!/^[\],:{}\s]*$/.test(
        text
          .replace(/\\["\\\/bfnrtu]/g, "@")
          .replace(
            /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
            "]"
          )
          .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
      );
    },
    isObjEmpty: (obj = {}) => {
      for (const x in obj) return false;
      return true;
    },
    isEdited(obj1 = {}, obj2 = {}) {
      for (let key in obj1) {
        const x = obj1[key];
        const y = obj2[key];

        switch (typeof x) {
          case "boolean":
            if (x !== y) {
              return true;
            }
          case "string":
            if (String(x).trim() !== String(y).trim()) {
              return true;
            }
            break;
          case "number":
            if (x !== y) {
              return true;
            }
            break;
          case "object":
            if (x?.id !== y?.id) {
              return true;
            }
          case "array":
            if (
              x?.length !== y?.length ||
              JSON.stringify(x) != JSON.stringify(y)
            ) {
              return true;
            }
          default:
        }
      }

      return false;
    },
    getEdited(obj1 = {}, obj2 = {}, inverseValue) {
      const prepare = {};
      for (let key in obj1) {
        const x = obj1[key];
        const y = obj2[key];

        const push = () => (prepare[key] = (inverseValue ? x : y) ?? null);

        switch (typeof x) {
          case "string":
            if (x.trim() !== String(y).trim()) {
              push();
            }
            break;
          case "number":
            if (x !== y) {
              push();
            }
            break;
          case "boolean":
            if (x !== y) {
              push();
            }
            break;
          case "object":
            if (x?.id !== y?.id || !x) {
              push();
            }
          default:
        }
      }
      return prepare;
    },
    prepareTva(price, tva, format) {
      price = +price;
      tva = +tva;

      return this.isNumber([price, tva])
        ? this._Multiply(price, this._Divide(this._Sum(100, tva), 100))
        : 0;
    },

    formatNumber(nr, options = {}, locales = "ro-RO") {
      return new Intl.NumberFormat(locales, options).format(nr);
    },

    excludeTva(price, tva, format) {
      price = +price;
      tva = +tva;

      return this.isNumber([price, tva])
        ? this._Multiply(this._Divide(price, this._Sum(100, tva)), 100)
        : 0;
    },
    dateChecker(date) {
      return date < Date.now();
    },
    RegQuote(str) {
      return this.isString(str)
        ? str.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1")
        : "";
    },
    clearUser() {
      localStorage.clear();
      this.setUserRole(false);
    },
    logout() {
      this.clearUser();
      this.$router.push("/login");
    },
    login(token) {
      this.clearUser();

      const decoded = this.$jwt.decode(token);

      const error = (msg) => {
        this.$toastr.e(msg || "Autorizarea a eșuat");
      };

      if (!decoded || !decoded.uuid) {
        error();
        return null;
      }
      if (decoded.userStatus == "disabled") {
        this.$toastr.w("Contul este dezactivat.");
        return "disabled";
      }

      localStorage.setItem("user_token", token);
      this.$toastr.Add({
        title: "Bun venit",
        msg: "Autorizarea a decurs cu succes",
        timeout: 3500,
      });

      this.goToHomePage();

      return true;
    },
    goToHomePage() {
      this.$router.push("/").catch(() => {});
    },
    checkHttpStatusCode(code) {
      return Number.isInteger(code) && String(code).charAt(0) == "2";
    },
    toDate(string) {
      return this.safeCallFn(window.$toDate, string);
    },
    toDateAndTime(string, showSeconds) {
      return this.safeCallFn(window.$toDateAndTime, string, showSeconds);
    },
    imgLoaded(el) {
      el.target.style.opacity = 1;
    },
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    fuseFilter(options, search, key, startsWithMode) {
      if (
        !Array.isArray(options) ||
        !(this.isString(search) || typeof search == "number")
      ) {
        return options;
      }
      const normalized = this.normalize(search)
        .toLowerCase()
        .trim();
      const filtered = options.filter((e) =>
        this.normalize(e[key])
          .toLowerCase()
          [startsWithMode ? "startsWith" : "includes"](normalized)
      );

      return filtered;
    },
    checkOwner(o, uuid, status) {
      if (!o) return false;
      if (o === true) return true;

      if (o == uuid) {
        return true;
      }
      if (
        Array.isArray(o) &&
        o.length > 1 &&
        o[0].includes(status) &&
        o[1] == uuid
      ) {
        return true;
      }
      return false;
    },
    getCurrentTime: () => new Date().getTime(),
    textFromHtml(text) {
      if (!this.isString(text)) return "";
      const x = document.createElement("div");
      x.innerHTML = text;
      return x.innerText;
    },
    localeReplacePoints(str) {
      const replaceCharMap = {
        ",": ".",
        ".": ",",
      };
      return str.replace(/[.,]/g, (char) => replaceCharMap[char] || char);
    },
    prepareLocaleNumber(val, isNotFloat = false, usePriceSymbol) {
      return numeral(+val).format(
        (usePriceSymbol ? "$ " : "") + (isNotFloat ? "0,0" : "0,0.00")
      );
    },
    isNumber(x, integer, returnVal) {
      const getVal = (val) =>
        isNaN(val)
          ? false
          : (integer
            ? Number.isInteger(val)
            : typeof val === "number")
          ? returnVal
            ? val
            : true
          : returnVal
          ? null
          : false;
      return Array.isArray(x)
        ? (() => {
            returnVal = false;

            return !!(x.length && x.every((e) => getVal(e)));
          })()
        : getVal(x);
    },
    parseNumber(x) {
      const pattern = /[^\d]/g;

      return this.isString(x)
        ? x.replace(pattern, "")
        : this.isNumber(x)
        ? String().replace(pattern, "")
        : "";
    },
    removeArrDuplicatesWithkeys(arr, keys = ["id"]) {
      if (!Array.isArray(arr)) return [];

      const Sets = {};
      for (let key of keys) Sets[key] = new Set();

      return arr.filter((e) => {
        e = e ?? {};

        const isDuplicate = (() => {
          const equals = [];

          for (let key of keys) {
            equals.push(Sets[key].has(e[key]));
          }

          return equals.every((e) => e);
        })();

        for (let key of keys) {
          if (e[key] !== undefined) {
            Sets[key].add(e[key]);
          }
        }

        return !isDuplicate;
      });
    },
    goToBasePage() {
      const x = this.$route.path.split("/")[1];
      if (x) {
        this.$router.push(`/${x}`).catch(() => {});
      }
    },
    safePushRouter(url) {
      if (!this.isString(url) || !url.trim() || this.$route.path == url) return;

      if (url.charAt(0) != "/") {
        url = `${this.$route.path}/${url}`;
      }

      this.$router.push(url).catch(() => {});
    },
    safeBackRoute() {
      const currUrl = this.$route.path;
      const backUrl = currUrl
        .split("/")
        .slice(0, 2)
        .join("/");

      if (currUrl !== backUrl) this.$router.push(backUrl);
    },
    compareArraysOfObj(arr1, arr2, keys = ["id"]) {
      const a = Array.isArray;
      if (!a(arr1) || !a(arr2) || arr1.length !== arr2.length) return false;
      keys = keys || [];

      for (let index in arr1) {
        const x = arr1[index] ?? {};
        const y = arr2[index] ?? {};

        if (keys.length) {
          for (let key of keys) {
            if (x[key] !== y[key]) return false;
          }
        } else {
          if (x !== y) return false;
        }
      }

      return true;
    },
    compareUnsortedArraysOfObj(arr1, arr2, keys = ["id"]) {
      const a = Array.isArray;
      arr1 = a(arr1) ? arr1 : [];
      arr2 = a(arr2) ? arr2 : [];

      if (arr1.length !== arr2.length) {
        return false;
      }

      if (!keys?.length) {
        keys = [];
      }

      const comparator = (a1, a2) =>
        a1.every((item) =>
          a2.find((item2) => {
            if (keys.length) {
              return !(() => {
                for (let key of keys) {
                  if (item[key] !== item2[key]) return true;
                }
                return false;
              })();
            } else {
              return item === item2;
            }
          })
        );

      return comparator(arr1, arr2) && comparator(arr2, arr1);
    },
    toLocaleNumber(val, notFloat, toFixed = 4) {
      if (!this.isString(val) && !this.isNumber(val)) return null;

      if (typeof val == "number") val = String(val);

      if (!this.isString(val) || isNaN(parseInt(val))) return "";

      if (parseInt(val.split(",")[1])) {
        val = String(this.toFixedIfNecessary(val.replace(",", "."), toFixed));
      }

      if (!notFloat) {
        const a = String(val)
          .replace(".", ",")
          .replace(/[^0-9,]/g, "")
          .split(",");
        if (!a[0]) {
          return "";
        }

        return a.slice(0, 2).join(",") + a.slice(2).join("");
      } else {
        return val.replace(/[^0-9]/g, "");
      }
    },
    getPaginatedParams: () => ({
      from: 0,
      to: localStorage.getItem("tableShowPerPage")
        ? parseInt(localStorage.getItem("tableShowPerPage"))
        : 15,
    }),
    walker(...p) {
      const walker = (params) => {
        if (p.length < 3) {
          params.splice(2, 1);
        }
        const obj = params[0] || {},
          path_to_key = params[1] || [],
          value = params[2],
          forced = params[3],
          has = Object.prototype.hasOwnProperty.bind(obj);

        const recursion = (
          v1 = obj[path_to_key[0]],
          v2 = path_to_key.slice(1),
          v3 = value,
          v4 = forced
        ) => walker([v1, v2, v3, v4]);

        if (!path_to_key.length) return;

        if (path_to_key.length === 1) {
          if (has(path_to_key[0])) {
            if (p.length > 2) obj[path_to_key[0]] = value;
            return obj[path_to_key[0]];
          } else {
            if (forced) {
              obj[path_to_key[0]] = value;
              return obj[path_to_key[0]];
            } else return;
          }
        } else {
          if (path_to_key[1] == "PREPARE_MAP") {
            if (Array.isArray(obj[path_to_key[0]])) {
              return Object.assign(
                obj[path_to_key[0]]
                  .map((e) => recursion(e, path_to_key.slice(2)))
                  .flat(1)
                  .filter((e) => e !== undefined),
                { PREPARE_MAP: true }
              );
            }
            return;
          }
          if (obj[path_to_key[0]] && typeof obj[path_to_key[0]] == "object")
            return recursion();
          else {
            if (forced) {
              obj[path_to_key[0]] = {};
              return recursion();
            } else return;
          }
        }
      };
      return walker(p);
    },
    checkParamsInObj(obj, arr) {
      if (!this.isObject(obj) || !Array.isArray(arr)) return false;

      for (let e of arr) {
        if (obj[e]) return true;
      }

      return false;
    },
    deepClone(obj) {
      if (typeof obj != "object") return null;

      if (typeof window.structuredClone == "function") {
        return window.structuredClone(obj);
      } else {
        return JSON.parse(JSON.stringify(obj));
      }
    },

    clearFields(obj, fields) {
      const toDetele = [...fields];

      for (const k of toDetele) {
        delete obj[k];
      }

      return obj;
    },
    deepObjectRemove(obj = {}, path_to_key = []) {
      if (path_to_key.length === 1) {
        delete obj[path_to_key[0]];
        return true;
      } else {
        if (obj[path_to_key[0]])
          return this.deepObjectRemove(
            obj[path_to_key[0]],
            path_to_key.slice(1)
          );
        else return false;
      }
    },
    readUserObject(x) {
      if (!this.isObject(x)) return "";

      return `${x.last_name ?? "-"} ${x.first_name ?? "-"} / ${x.id ?? "-"}`;
    },
    getLocaleNumber(str) {
      str = String(str);
      return this.toFixedIfNecessary(numeral(str)._value, 4);
    },
    getLocaleNumberBeautified(str) {
      return parseFloat(String(str).replace(",", ""));
    },
    isString(str, returnVal, trim) {
      let isStr = Object.prototype.toString.call(str) === "[object String]";
      if (trim && isStr) isStr = !!str.trim();

      return isStr ? (returnVal ? str : true) : returnVal ? "" : false;
    },
    isObject(val, returnVal) {
      return Object.prototype.toString.call(val) === "[object Object]"
        ? returnVal
          ? val
          : true
        : returnVal
        ? {}
        : false;
    },
    isPromise(val) {
      return Object.prototype.toString.call(val) === "[object Promise]";
    },
    normalize(str) {
      if (!this.isString(str)) return "";
      return str.normalize("NFD").replace(/[\u0300-\u036F]/g, ""); //.replace(districts, char => districtFixMap[char] || char)
    },
    getExtension(str) {
      if (!this.isString(str)) return "";
      return str.split(".").pop();
    },
    makeid(length) {
      let result = [];
      let characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result.push(
          characters.charAt(Math.floor(Math.random() * charactersLength))
        );
      }
      return result.join("");
    },
    uppercaseFirst(string) {
      return this.isString(string)
        ? string
            .trimLeft()
            .charAt(0)
            .toUpperCase() + string.trimLeft().slice(1)
        : "";
    },
    statusNormalize(status) {
      return (
        needStatusesMap[
          String(status)
            .trim()
            .toLowerCase()
        ] || ""
      );
    },
    initPermision(rule, permisionKey, statuses = [], permObject) {
      const cRole = this.safeCallFn(window.$getRoleAll);
      const pList = window.$P;

      if (!cRole || !pList) {
        return;
      }

      const role = String(cRole.role)
        .trim()
        .toLowerCase();
      const keys = Array.from(Object.keys(pList));
      const findRule = keys.filter((e) =>
        String(e)
          .trim()
          .startsWith(`${rule}:`)
      );

      const isMainRule = Array.isArray(pList[rule]);

      permObject = this.isObject(permObject) ? permObject : this.PERMISIONS;

      permObject[permisionKey] = false;
      if (findRule.length) {
        const prepare = [];
        findRule.forEach((r) => {
          if (Array.isArray(pList[r]) && pList[r].find((e) => e == role)) {
            statuses.forEach((status) => {
              if (r.includes(`:${status}`)) {
                prepare.push(status);
              }
            });
          }
        });
        permObject[permisionKey] = prepare.length ? prepare : false;
      }
      if (
        !permObject[permisionKey] &&
        isMainRule &&
        pList[rule].find((e) => e == role)
      ) {
        permObject[permisionKey] = true;
      }
      if (!permObject[permisionKey]) {
        if (isMainRule && pList[rule].find((e) => e == "<owner>")) {
          permObject[permisionKey] = this.getUuid() || false;
        } else {
          const prepare = [];
          findRule.forEach((r) => {
            if (
              Array.isArray(pList[r]) &&
              pList[r].find((e) => e == "<owner>")
            ) {
              statuses.forEach((status) => {
                if (r.includes(`:${status}`)) {
                  prepare.push(status);
                }
              });
            }
          });
          permObject[permisionKey] =
            prepare.length && this.getUuid()
              ? [prepare, this.getUuid()]
              : false;
        }
      }
    },
    changeRoleView(role) {
      if (!role) return "";
      const prepareRole = String(role)
        .trim()
        .toLowerCase();
      const converter = {
        superadmin: "Superadmin",
        admin: "Administrator",
        admin_institutie: "Admin instituție",
        sef_institutie: "Șef instituție",
        supervisor: "Supervizor",
        functionar: "Funcționar",
        responsabil_achizitii: "Responsabil achiziții",
        supervizor_achizitii: "Supervizor achiziții",
        responsabil_achizitii_extern: "Responsabil achiziții externe",
        responsabil_buget: "Responsabil buget",
      };
      return converter[prepareRole] || prepareRole;
    },
    getToken() {
      const decodeToken = this.$jwt.decode(this.$jwt.getToken());
      return decodeToken || null;
    },
    getUserAvatar() {
      const x = localStorage.getItem("USER_AVATAR_BASE64");

      return isBase64UrlImage(x) ? x : "";
    },
    getUuid() {
      const decodeToken = this.$jwt.decode(this.$jwt.getToken());
      if (decodeToken && decodeToken.uuid) {
        return String(decodeToken.uuid).trim();
      }
      return "";
    },
    getUserName() {
      const decodeToken = this.$jwt.decode(this.$jwt.getToken());
      if (decodeToken) {
        return `${decodeToken.last_name || ""} ${decodeToken.first_name || ""}`;
      }
      return "";
    },
    isAuthorized() {
      return this.isObject(this.safeCallFn(window.$getRoleAll));
    },
    getUserRole(onlyId, getAll) {
      const role = this.safeCallFn(window.$getRoleAll);
      return getAll ? role : onlyId ? role?.id : role?.role || "";
    },
    getBeautifiedRole() {
      return this.changeRoleView(this.getUserRole());
    },
    toFixedIfNecessary(value, dp) {
      const x = +parseFloat(value).toFixed(dp);
      return isNaN(x) ? "" : x;
    },
    calcPercent(val, range = 100, fixedDecimals = 2) {
      if (!val || !range) return 0;
      return this._Multiply(
        this.toFixedIfNecessary(val, fixedDecimals),
        range
      ).toFixed(fixedDecimals);
    },
    getUserDepartment(fullReturn) {
      const role = this.safeCallFn(window.$getRoleAll);
      if (!role) {
        return "";
      }

      if (fullReturn) {
        return {
          name: role.departmentName,
          id: role.departmentId,
        };
      }

      return role.departmentName || "";
    },
    invalidBlob(file) {
      return !file?.type || file.type == "application/json" || file.size < 1000;
    },
    getNeedFileName(string, withoutExtension) {
      if (!this.isString(string)) return "";
      let prepare =
        string
          .split("_")
          .slice(1)
          .join("_") || string;

      return withoutExtension
        ? prepare.substring(0, prepare.lastIndexOf(".")) || prepare
        : prepare;
    },
    getUserInstitution(fullReturn) {
      const role = this.safeCallFn(window.$getRoleAll);
      if (!role) {
        return "";
      }

      if (fullReturn) {
        return {
          name: role.institutionName,
          id: role.institutionId,
        };
      }

      return role.institutionName || "";
    },
    toPriceFormat(val) {
      if (!this.isString(val) && !this.isNumber(val)) return null;

      const x = this.safeCallFn(window.$toPriceFormat, val);
      return { NaN: "-" }[x] || x;
    },
    dateFeatureChecker(date) {
      return new Date() > date;
    },
    getMaxOfObjects(arr, key = "id") {
      if (!Array.isArray(arr) || !key) return;

      return Math.max.apply(
        null,
        arr.map((e) => e[key])
      );
    },
    combineObjects(versions, showOnlyFinalPrepare) {
      if (!Array.isArray(versions)) return [];

      const preparedVersions = [];
      let prepare = {};

      for (let curr of versions) {
        if (!this.isObject(curr)) continue;

        prepare = { ...prepare, ...curr };
        preparedVersions.push(prepare);
      }
      return showOnlyFinalPrepare ? prepare : preparedVersions;
    },
    objValidator(obj, callback = () => {}) {
      if (!this.isObject(obj) || typeof callback != "function") return;

      const { validator } = window;
      const data = Object.entries(obj);
      let wrong = false;

      for (let item of data) {
        if (!validator.call(item[1])) {
          wrong = true;
          callback(item[0]);
        }
      }

      return !wrong;
    },
    getFirstPath(str) {
      str = str?.path || str || this.$route.path;
      if (!this.isString(str)) return "";

      return str.split("/", 2).join("/");
    },
    getSecondPath(str) {
      str = str?.path || str || this.$route.path;
      if (!this.isString(str)) return "";

      return "/" + str.split("/")[2];
    },
    isValidValue(val) {
      if (val instanceof Date) {
        return true;
      }

      switch (typeof val) {
        case "string":
          return !!val.trim();

        case "number":
          return !isNaN(+val);

        case "boolean":
          return val;

        default:
          return !!val;
      }
    },
    containsValidValues(obj) {
      if (typeof obj != "object") return false;

      const recursion = (value) => {
        if (typeof value === null) {
          return false;
        }
        if (value instanceof Date) {
          return true;
        }
        if (typeof value == "object") {
          for (let item of Object.values(value)) {
            if (item && typeof item == "object") {
              const x = recursion(item);
              if (x) return true;
            } else if (this.isValidValue(item)) {
              return true;
            }
          }
        }
        return false;
      };

      return recursion(obj);
    },
    filterManager(scheme = {}, filterValues = {}, data = []) {
      const getDataFromScheme = () =>
        this.combineObjects(
          Object.entries(scheme).map((e) => ({
            [e[0]]: e[1]
              ? ((x) => (e[1].range ? [x, x] : x))(
                  {
                    undefined: "",
                    number: "",
                    date: null,
                    objId: null,
                  }[e[1].type]
                )
              : "",
          })),
          true
        );

      if (!this.isObject(scheme) || !Array.isArray(data)) return null;
      if (filterValues === true) {
        return getDataFromScheme();
      }
      if (!this.isObject(filterValues)) return null;
      if (!data.length) return [];

      const isFn = (fn) => typeof fn == "function";

      const comparator = (val1, val2, type, options = {}) => {
        switch (type) {
          case "string":
            return (() => {
              if (Array.isArray(val1)) return false;

              val1 = this.normalize(String(val1));
              val2 = this.normalize(String(val2));

              const trim = (val) => val.trim().toLowerCase();

              return trim(val2).includes(trim(val1));
            })();

          case "number":
            return (() => {
              const x = +val2;
              if (!Array.isArray(val1)) {
                return +val1 === x;
              }
              const chk = (num) =>
                ![null, ""].includes(num) && this.isNumber(+num);
              const n1 = val1[0];
              const n2 = val1[1];

              if ((chk(n1) && +n1 > x) || (chk(n2) && +n2 < x)) {
                return false;
              }

              return true;
            })();

          case "date":
            return (() => {
              if (!val1 || !val2) {
                return false;
              }
              const getTime = (x, addDay) =>
                new Date(x || "")?.getTime() + (addDay ? 86399000 : 0);
              val2 = getTime(val2);

              return Array.isArray(val1)
                ? getTime(val1[0]) <= val2 && getTime(val1[1], true) >= val2
                : getTime(val1) <= val2 && getTime(val1, true) >= val2;
            })();

          case "objId":
            return (() => {
              const getKey = (val) => val[options.objIdKey || "id"];
              const chk = (val) => this.isObject(val);

              return chk(val1) && chk(val2) && getKey(val1) === getKey(val2);
            })();

          default:
            return false;
        }
      };

      const prepare = [];
      const filterMap = [];

      const filterValueChecker = (value, valueType) => {
        if (valueType == "string") {
          return this.isString(value) && !!value.trim();
        }
        if (Array.isArray(value)) {
          return this.containsValidValues(value);
        }
        if (["objId", "number"].includes(valueType)) {
          return value !== null;
        }

        return false;
      };

      Object.entries(scheme).forEach((e) => {
        const filterValue = isFn(e[1].extractFn)
          ? e[1].extractFn.call(this, filterValues[e[0]])
          : filterValues[e[0]];
        const valueType = e[1].type || "string";
        const path = e[1].path;
        const isArray = Array.isArray(path);

        let preparedPath = null;

        if (!filterValueChecker(filterValue, valueType)) {
          return;
        }

        if (isArray) {
          if (!this.containsValidValues(path)) return;

          preparedPath = Object.assign(
            path
              .filter((e) => !!e)
              .map((e) => (this.isString(e) ? e.split(".") : e)),
            { isArr: true }
          );
        } else if (this.isString(path)) {
          if (!path.trim()) return;

          preparedPath = path.split(".");
        } else if (isFn(e[1].prepareFn)) {
          preparedPath = e[1].prepareFn;
        } else return;

        filterMap.push([filterValue, preparedPath, e[1]]);
      });

      if (!this.containsValidValues(filterMap)) {
        return [];
      }

      for (let item of data) {
        if (!this.isObject(item)) continue;

        let notEqual = false;
        for (let filter of filterMap) {
          if (notEqual) break;

          const value1 = filter[0];
          const valueType = filter[2].type || "string";
          const prepareFn = isFn(filter[1]) ? filter[1] : null;

          const compareFn = (value) => {
            if (Array.isArray(value) && value.PREPARE_MAP === true) {
              for (let e of value) {
                if (!compareFn(e)) {
                  return false;
                }
              }
              return true;
            }

            const compare = (val) =>
              !comparator(val, value, valueType, filter[2]);

            if (filter[2].multiple) {
              if (Array.isArray(value1)) {
                for (let filterValue of value1) {
                  if (!compare(filterValue)) {
                    return false;
                  }
                }
              }

              return true;
            }

            return compare(value1);
          };

          if (prepareFn) {
            notEqual = compareFn(prepareFn.call(this, item));
            continue;
          }

          if (!filter[1].isArr) {
            notEqual = compareFn(this.walker(item, filter[1]));
          } else {
            let validPath = false;
            for (let path of filter[1]) {
              if (validPath) break;
              const val = this.walker(item, path);

              if (val === undefined) continue;

              validPath = !compareFn(val);
            }
            notEqual = !validPath;
          }
        }
        if (!notEqual) {
          prepare.push(item);
        }
      }

      return prepare;
    },
    isDomElement(obj) {
      try {
        //Using W3 DOM2 (works for FF, Opera and Chrome)
        return obj instanceof HTMLElement;
      } catch (e) {
        //Browsers not supporting W3 DOM2 don't have HTMLElement and
        //an exception is thrown and we end up here. Testing some
        //properties that all elements have (works on IE7)
        return (
          typeof obj === "object" &&
          obj.nodeType === 1 &&
          typeof obj.style === "object" &&
          typeof obj.ownerDocument === "object"
        );
      }
    },
    scrollElToTOp(el) {
      if (this.isDomElement(el)) {
        if (typeof el.scrollIntoView == "function") {
          el.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        } else {
          el.scrollTop = el.scrollHeight;
        }
      }
    },
  },
};

<template>
  <input
    ref="currencyInput"
    :value="disabled ? disabledValue : val"
    type="text"
    class="vInput"
    :class="disabled ? 'form-disabled' : ''"
    :disabled="disabled"
    @blur="formatCurrency('blur')"
    @input="(event) => updateCurrentVal(event.target.value)"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    min: {
      type: [String, Number],
      default: "",
    },
    max: {
      type: [String, Number],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    floatLength: {
      type: Number,
      default: 2,
    },
    minFloatLength: {
      type: Number,
      default: 2,
    },
  },

  watch: {
    value(newVal) {
      this.disabledValue = this.formatCurrentValue(
        parseFloat(newVal == "" ? 0 : newVal)
          .toFixed(2)
          .replace(/\./g, ","),
        "blur"
      );
    },
    deep: true,
    immediate: true,
  },

  methods: {
    updateCurrentVal(e) {
      this.val = e;
      this.formatCurrency();
    },

    formatNumberLocal(n) {
      return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    formatCurrentValue(inputVal, blur) {
      const input = this.$refs.currencyInput;

      if (inputVal === "") {
        return;
      }

      var originalLen = inputVal.length;

      var caretPos = input.selectionStart;

      if (inputVal.indexOf(",") >= 0) {
        var decimalPos = inputVal.indexOf(",");

        var leftSide = inputVal.substring(0, decimalPos);

        var rightSide = inputVal.substring(decimalPos);

        leftSide = this.formatNumberLocal(leftSide);

        rightSide = this.formatNumberLocal(rightSide);

        if (blur === "blur") {
          rightSide += "00";
        }

        rightSide = rightSide.substring(0, 2);

        inputVal = leftSide + "," + rightSide;
      } else {
        inputVal = this.formatNumberLocal(inputVal);

        if (blur === "blur") {
          inputVal += ",00";
        }
      }
      var updatedLen = inputVal.length;
      caretPos = updatedLen - originalLen + caretPos;

      input.setSelectionRange(caretPos, caretPos);

      return inputVal;
    },

    formatCurrency(blur) {
      this.val = this.formatCurrentValue(this.val, blur);

      if (!this.val || this.val == "") {
        if (!this.disabled) this.$emit("input", 0);
      } else {
        if (!this.disabled)
          this.$emit(
            "input",
            parseFloat(this.val.replace(/\./g, "").replace(/,/g, "."))
          );
      }
    },
  },

  data() {
    return {
      val: parseFloat(this.value == "" || !this.value ? 0 : this.value)
        .toFixed(2)
        .replace(/\./g, ","),

      disabledValue: parseFloat(
        this.value == "" || !this.value ? 0 : this.value
      )
        .toFixed(2)
        .replace(/\./g, ","),
    };
  },

  mounted() {
    this.formatCurrency("blur");
  },

  created() {},
};
</script>

<template>
  <div @click="$emit('click')" id="NotificationParent">
    <div v-if="loading">
      <i class="fas fa-sync fa-spin"></i>
    </div>
    <v-popover
      ref="popover"
      v-else
      popoverBaseClass="emptyPopover"
      offset="8"
      :boundariesElement="null"
    >
      <span id="NotificationBtn">
        <span
          :style="notifyLength ? 'background-color: #e91e63 !important;' : ''"
          >{{ notifyLength }}</span
        >
        <i class="fas fa-bell"></i>
      </span>

      <div id="NotificationPopup" slot="popover">
        <div id="NotificationHeader">
          <div id="NotificationCount">
            {{ notifyLength }} Notificări noi
            <small style="font-size: 1.45rem;" @click="closePopover()"
              ><router-link to="/notificari">vezi toate</router-link></small
            >
          </div>
          <div>
            <a
              v-show="notifyLength"
              @click.prevent="readAllNotifications"
              href="#"
              id="NotificationRead"
            >
              Citește tot
              <i class="fas fa-check-double"></i>
            </a>
          </div>
        </div>
        <div v-if="!notifyLength" id="NotificationNone">
          <i class="fas fa-bell-slash"></i>
          <h4 class="prettyH4">Nu sunt notificări</h4>
        </div>
        <div v-else id="NotificationBody">
          <div v-for="(notify, i) in newNotifications" :key="i" class="NotItem">
            <div class="NotItemIcon">
              <div>
                <i class="far fa-bell"></i>
              </div>
            </div>
            <div class="NotItemContent">
              <span class="is-link" @click="openReference(notify)">
                {{ notify.message }} -
                {{
                  new Date(notify.createdAt).toLocaleString("ro-ro", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })
                }}</span
              >
            </div>
            <div class="NotItemControls">
              <div v-if="notify._loading" data-loadSpin style="width: 3rem">
                <i class="fas fa-sync fa-spin"></i>
              </div>
              <div v-else-if="!notify.isRead" @click="readNotification(notify)">
                Citește <i class="fas fa-check"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-popover>
  </div>
</template>

<style src="@/assets/Notification.css"></style>

<script>
import {
  EVENT_NOTIFICATION,
  EVENT_NOTIFICATION_READ,
  NEEDS_ONCE,
  REFERENCE_ONCE,
  REFERENCE_LAST_VERSION,
  NEEDS_LAST_VERSION,
  ACQUISITIONS,
  VERBAL_PROCESS,
  ANNOUNCEMENT,
} from "@/api.js";
import { mapGetters, mapMutations } from "vuex";

const RELOAD_TIME = 30000;
let HOT_RELOAD_INTERVAL = null;

export default {
  computed: {
    ...mapGetters(["userRole", "notifications"]),
    newNotifications() {
      return this.notifications.filter((e) => !e.isRead);
    },
    notifyLength() {
      return this.newNotifications.length;
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    userRole() {
      this.starter();
    },
  },
  methods: {
    ...mapMutations({ addWindow: "addWindow", set: "setNotifications" }),

    openReference(notify) {
      if (notify.notifyType == "need-reference") {
        const mustReload = this.$route.path.includes("/planificare/referate");
        REFERENCE_ONCE(notify.parentId)
          .then((res) => {
            if (
              !this.checkHttpStatusCode(res?.meta?.HttpStatusCode) ||
              (res.data.result[0] &&
                (res.data.result[0].needReferateStatus == "approved" ||
                  res.data.result[0].needReferateStatus == "signature_waiting"))
            ) {
              REFERENCE_LAST_VERSION(notify.parentId).then((res) => {
                if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                  this.readNotification(notify);

                  this.$router.push(
                    `/planificare/referate/${notify.parentId}/${res.data?.result?.versionNumber}`
                  );
                } else {
                  this.$toastr.w("Referatul nu mai poate fi deschis");
                }
              });
            } else {
              this.readNotification(notify);

              this.$router.push("/planificare/referate/" + notify.parentId);
            }
          })
          .catch((err) => {
            this.$toastr.w("Referatul nu mai poate fi deschisă");
          });
      } else if (notify.notifyType == "need") {
        NEEDS_LAST_VERSION(notify.parentId).then((res) => {
          this.openLastVersionNeed(res.data.result);
          this.readNotification(notify);
          this.$router.push(`/planificare/nevoi`);
        });
      } else if (notify.notifyType == "product") {
        this.$router
          .push({
            path: `/planificare/catalog`,
            query: { product: notify.parentId },
          })
          .catch(() => {});

        this.readNotification(notify);
      } else if (notify.notifyType == "acquisition") {
        this.goToProcedure(ACQUISITIONS.getOne, notify.parentId, "achizitii");
        this.readNotification(notify);
      } else if (notify.notifyType == "announcement") {
        this.goToProcedure(ANNOUNCEMENT.getOne, notify.parentId, "anunt");
        this.readNotification(notify);
      } else if (notify.notifyType == "verbal-process") {
        this.goToProcedure(
          VERBAL_PROCESS.getOne,
          notify.parentId,
          "proces-verbal"
        );
        this.readNotification(notify);
      } else if (notify.notifyType == "paap") {
        this.$router.push(`/planificare/paap`);
        this.readNotification(notify);
      }

      this.closePopover();
    },

    goToProcedure(api, id, type) {
      api(id).then((res) => {
        const data = res?.data?.result;

        const procedura = data.paapCentralization.procedureType.name
          .replace(/ /g, "_")
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        const path = `/atribuire/procedura/${procedura}/${data.paapCentralization.id}/${type}`;

        this.$router.push(path);
      });
    },

    async openLastVersionNeed(data) {
      const version = data?.data || null;

      if (typeof version.createdByUser == "string") {
        await USERS_ONCE(version.createdByUser, true)
          .then((res) => {
            res = res?.data?.result || null;
            version.createdBy = {
              last_name: res?.last_name || "-",
              first_name: res?.first_name || "-",
              idConectX: res?.id || "-",
            };
          })
          .catch((err) => {
            this.$toastr.e("Detectarea solicitantului versiunii a eșuat.");
            version.createdBy = {
              last_name: "-",
              first_name: "-",
              idConectX: "-",
            };
          });
      }
      if (typeof data.createdBy == "string") {
        const parseName = data.createdBy.split(" ");
        version.modifiedBy = {
          last_name: parseName[1],
          first_name: parseName[0],
          idConectX: data.createdBy.split("=")[1],
        };
      }
      let approver =
        Array.isArray(version.approvers) && version.approvers[0]
          ? version.approvers[0]
          : false;

      approver = this.JSONvalide(approver)
        ? JSON.parse(approver)[0]?.split("*")
        : this.isString(approver, true)?.split("*") || [];

      this.addWindow({
        title: `Versiunea V${data.versionNumber}`,
        isCurrent: true,
        // subtitle: `Nevoie: ${productName.split(':')[1]}`,
        data: {
          //all: this.Data.VERSIONS,
          needData: {
            ...version,
            showModified: true,
            approver: approver[2] ? approver[2] : false,
          },
          additionalData: data,
          status: version.status,
          canDelete: false,
        },
        modal: 11.1,
      });
    },

    closePopover() {
      const x = this.$refs.popover?.hide;

      if (x) x();
    },
    clearNotifycations() {
      this.set([]);
    },
    readAllNotifications() {
      if (!this.notifyLength) return;
      const promises = [];
      for (let e of this.newNotifications) {
        const read = this.readNotification(e, true);

        if (read) promises.push(read);
      }

      Promise.all(promises).then(() => this.syncData(true));
    },
    readNotification(data, onlyCheck) {
      if (!Number.isInteger(data?.id) || data._loading) {
        return;
      }

      data._loading = true;
      const call = EVENT_NOTIFICATION_READ(data.id);

      if (onlyCheck) return call;
      call.then(() => this.syncData(true));
    },
    syncData(hiddenSync) {
      if (!hiddenSync) {
        this.clearNotifycations();
      }
      ((x) => {
        if (!hiddenSync) this.loading = true;
        const q = this.getUuid();
        const w = this.getUserRole(true);
        if (!q || !w) {
          this.loading = false;
          return;
        }
        EVENT_NOTIFICATION(q, w).then((e) => {
          if (x !== this.userRole?.id) {
            return;
          }
          this.loading = false;
          if (Array.isArray(e?.data?.result)) {
            this.set(e.data.result.map((e) => ({ ...e, _loading: false })));
          }
        });
      })(this.userRole?.id);
    },
    starter() {
      clearInterval(HOT_RELOAD_INTERVAL);

      this.syncData();

      HOT_RELOAD_INTERVAL = setInterval(this.syncData, RELOAD_TIME, true);
    },
  },
  beforeDestroy() {
    clearInterval(HOT_RELOAD_INTERVAL);
  },
  created() {
    this.starter();
  },
};
</script>

<style>
.is-link {
  cursor: pointer;
}
.is-link:hover {
  color: #337ab7;
}
</style>
